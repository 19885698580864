import React from 'react';
import { Container } from 'components/Container';
import { useTranslation } from 'hooks/useTranslation';
import NotFoundIcon from 'assets/notFoundIcon.svg';
import styles from './custom404.module.scss';
import { Button } from 'components/Button';
import { useUILanguage } from 'hooks/useUILanguage';
import { serviceRoutes } from 'utils/routes';
import Head from 'next/head';

const Custom404: React.FunctionComponent = () => {
  const language = useUILanguage();
  const t = useTranslation();
  const pageTitle =
    language === 'sv'
      ? `${t('notFoundHeading')} | Yle Arenan`
      : `${t('notFoundHeading')} | Yle Areena`;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <Container>
        <div className={styles.root}>
          <div className={styles.imageWrapper}>
            <NotFoundIcon className={styles.icon} />
          </div>
          <div className={styles.contentWrapper}>
            <h1 className={styles.title}>{t('notFoundHeading')}</h1>
            <p className={styles.text}>{t('notFoundText')}</p>
            <div className={styles.buttonWrapper}>
              <Button
                pointer={{
                  uri: `/${serviceRoutes['tv'][language]}`,
                }}
                size="sm"
                text={t('notFoundButton')}
              ></Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Custom404;
